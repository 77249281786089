<template>
    <ul>
        <mini-detail
            v-for="row in rows"
            :key="row.id"
            :row="row"
            :category="category"
            @hover="onHover"
            @click="onClick"
        />
    </ul>
</template>

<script>
import MiniDetail from './components/mini-detail';
import { mapGetters, mapState } from 'vuex';
export default {
    components: {
        MiniDetail,
    },
    props: {
        layer: {
            type: Object,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        rows: [],
        category: {},
    }),
    created() {
        this.category = this.findCategoryBySlug(this.layer.category);
    },
    computed: {
        ...mapState('records', ['dataContentRecords']),
        ...mapGetters('categories', ['findCategoryBySlug']),
        records() {
            return this.dataContentRecords[this.layer.id];
        },
    },
    watch: {
        records: {
            deep: true,
            handler: 'checkCategoryRecordsAvailable',
            immediate: true,
        },
    },
    methods: {
        checkCategoryRecordsAvailable() {
            if (!this.records) {
                this.$emit('rows', []);
                return;
            }

            this.rows = this.records?.features;

            this.$emit('rows', this.rows);
        },
        onClick(row) {
            this.$emit('click', row);
            let inBounds = this.map.map.getBounds().contains(row.geometry.coordinates);
            if (!inBounds || this.map.map.getZoom() < 14) {
                this.map.map.flyTo({
                    center: row.geometry.coordinates,
                    zoom: 14,
                });
            }
        },
        onHover(row) {
            this.$events.$emit('map:popup:enter', {
                coordinates: row.geometry.coordinates,
                html: row.properties.display_details.name ?? row.properties.name,
            });
        },
    },
};
</script>
